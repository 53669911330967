@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Domaine;
  font-weight: 100;
  src: url('/static/fonts/domaine-text-web-regular.woff') format('woff');
}

@font-face {
  font-family: Domaine;
  font-weight: 300;
  src: url('/static/fonts/domaine-text-web-regular.woff') format('woff');
}

@font-face {
  font-family: Domaine;
  font-weight: 400;
  src: url('/static/fonts/domaine-text-web-medium.woff') format('woff');
}

@font-face {
  font-family: Domaine;
  font-weight: 700;
  src: url('/static/fonts/domaine-text-web-bold.woff') format('woff');
}

@layer base {
  :root {
    font-family: 'Domaine', sans-serif;
  }
}

html {
  font-size: 16px;
}

@media (min-width: 961px) {
  html {
    font-size: 18px;
  }
}
